<script>
  import { params } from "@roxi/routify";
  import { _, locale, init } from "svelte-i18n";
  import Locale from "../../locale/Locale.svelte";
  import { confirmStripePayment } from "../../payment/stripe";
  import StripeDeclinePopUp from "../../components/stripe/StripeDeclinePopUp.svelte";
  import { getThankYouMessage } from "../../utils";
  import ShopifyEmbed from "../../components/ShopifyEmbed.svelte";
  const { orderID = null, online = false, redirect_status = null } = $params;
  let showingDeclinePopUp = false,
    declineError = "";

  let language = localStorage.getItem("enrol-app-locale");
  locale.set(language);
  init({
    fallbackLocale: "en",
    initialLocale: language,
  });
  async function confirm() {
    await confirmStripePayment({ id: orderID });
  }

  if (redirect_status == "succeeded") {
    confirm();
  }

  if (redirect_status == "failed") {
    showingDeclinePopUp = true;
    declineError = "Error";
    window.location.replace(`${window.location.origin}/bardic/payment`);
  }

  let thank_you_message, price_value, firstName, lastName;
  $: {
    if (thank_you_message) {
      thank_you_message = thank_you_message
        .replaceAll("{orderID}", orderID)
        .replaceAll("{firstName}", firstName)
        .replaceAll("{lastName}", lastName);

      if (price_value) {
        thank_you_message = thank_you_message.replaceAll(
          "{price_value}",
          price_value || ""
        );
      }
    }
  }
  getThankYouMessage(orderID).then((res) => {
    if (res) {
      thank_you_message = res.price.variant.product.thank_you_message;
      firstName = res.first_name;
      lastName = res.last_name;
      price_value = res.price.value;
    }
  });
</script>

<StripeDeclinePopUp bind:visible={showingDeclinePopUp} error={declineError} />
<div style="text-align: justify;">
  <Locale />
  {#if thank_you_message}
    <br />
    {@html thank_you_message
      .replaceAll("{Shopify1}", "")
      .replaceAll("{shopify1}", "")}
    {#if thank_you_message.includes("{Shopify1}") || thank_you_message.includes("{shopify1}")}
      <h2>{$_("success.Add a Gwersi Storage Box to your order?")}</h2>
      <ShopifyEmbed />
    {/if}
  {/if}
</div>
<div class="btn-container">
  <a class="btn btn--primary" href={$_("success.back_to_website_url")}
    >{$_("success.Back to the OBOD website")}</a
  >
</div>

<style>
  p,
  ul {
    margin: 0.5em 0;
  }

  p {
    text-align: justify;
  }

  li {
    margin-left: 1em;
    font-style: italic;
  }
</style>
